import React from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toastOptions } from "../../config/toast.config";

function TokenError() {
  toast.error("Invalid token", toastOptions);
  return(
  <>
    <Navigate to={'/login'} />
  </>);
}

export default TokenError;
