import * as React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';
import { createConsultation, deleteConsultation, getPatientsList } from '../../../services/api.service';
import Form from "react-bootstrap/Form"
import { Link, useParams } from 'react-router-dom';
import { getConsultationsList } from '../../../services/api.service'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { ModalBody, ModalHeader, MODAL_CONFIGURATIONS, MODAL_EVENTS, MODAL_TYPES, openModal } from '../../../services/modal.service';
import { publish, subscribe, unsubscribe } from '../../../services/event.service';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import { toastOptions } from '../../../config/toast.config';
import { useFormik } from 'formik';
import { consultationSchema } from '../../../helpers/validations';
import Button from 'react-bootstrap/esm/Button';

var $consultationId = null

function DeleteConsultationPrompt() {
  return (
    <>
      <ModalHeader>
      <h5 className='mb-0'>Delete Visit</h5>
      </ModalHeader>
      <ModalBody>
        <p className='text-center px-3'>Are you sure you want to delete visit?</p>
      </ModalBody>
    </>
  )
}


function AddConsultationModal({setConsultationId, callbackFn}) {
  const formik = useFormik({
      initialValues : {
          consultation_name: ""
      },
      validationSchema: consultationSchema, 
      onSubmit : async function(values) {
          createConsultation(values.consultation_name)
          .then(response => {
              setConsultationId(response.data.consultation_id)
          })
          .catch(err => console.log(err))
          .finally(() => callbackFn())
      }
  })

  return (
      <section>
          <ModalHeader><h5 className='mb-0'>Add Visit</h5></ModalHeader>
          <div className="p-3 consultation-modal">
              <Form className="row" onSubmit={formik.handleSubmit}>
                  <div className="col-12">
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Label>Patient Initials</Form.Label>
                          <Form.Control type="text" name="consultation_name" placeholder="Patient Inititals" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.consultation_name} required/>
                          { formik.touched.consultation_name && formik.errors?.consultation_name && <small className="text-danger">{formik.errors?.consultation_name}</small> }
                      </Form.Group>
                      <Button variant="primary" type="submit" >Add Visit</Button> 
                  </div>
              </Form>
          </div>
      </section>
  )
}

const headCells = [
  {
    id: 'createdAt',
    align: 'left',
    label: 'Visit date',
    isSortable: true,
    width: 40
  },
  {
    id: 'consultation_name',
    align: 'left',
    label: 'Patient Initials',
    width: 40
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>

        {/* Checkbox */}

        {props.isCheckboxAllowed && 
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
        }

        {/* Head cells */}

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={'none'}
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell.width+'%'} 
          >
          {headCell.isSortable 
            ? <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel> 
            : headCell.label}  
            
          </TableCell>
        ))}

        {/* Actions */}

        <TableCell
          align={'left'}
          padding={'none'}
        >
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function ConsultationLists({CurrentConsultationIdContext}) {

  const {currentConsultationId, setCurrentConsultationId} = React.useContext(CurrentConsultationIdContext);

  const [data, setData] = React.useState({rows: [], totalCount: 0})
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('createdAt');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [isCheckboxAllowed, setIsCheckboxAllowed] = React.useState(false);
  const [query, setQuery] = React.useState("");
  const [searchQuery, setSearchQuery] = React.useState("")
  const [consultationDate, setConsultationDate] = React.useState(moment());
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    fetchConsultationLists();
  }, [page, rowsPerPage, order, searchQuery, consultationDate])
  

  React.useEffect(() => {
    const timeOut = setTimeout(() => {setSearchQuery(query)}, 500);
    return () => {clearTimeout(timeOut)}
  }, [query])

  React.useEffect(() => {
    subscribe(MODAL_EVENTS.MODAL_CLOSE, fetchConsultationLists)
    return () => {unsubscribe(fetchConsultationLists)}
  }, [])

  React.useEffect(() => {
    $consultationId = currentConsultationId
  }, [currentConsultationId])
  
  const fetchConsultationLists = async function() {
    setIsLoading(true);
    setData({rows: [], totalCount: 0})
    try {
      const res = await getConsultationsList(page+1, rowsPerPage, orderBy, order, searchQuery, moment(consultationDate).format('YYYY-MM-DD'));
      setData({rows: res.data.consultations, totalCount: res.data.total_count})
      if(!$consultationId && res.data.consultations.length) {
        setCurrentConsultationId(res.data.consultations[0].consultation_id)
      }
    } catch (error) {
      console.log(error)
    }finally{
      setIsLoading(false);
    }
  }

  const onCreateNewConsultation = function() {
    const callbackFn = function() {
      publish(MODAL_EVENTS.MODAL_CLOSE)
    }
    const modalConfiguration = MODAL_CONFIGURATIONS.find((conf) => conf.type == MODAL_TYPES.COMPONENT)
    modalConfiguration.component = (<AddConsultationModal setConsultationId={setCurrentConsultationId} callbackFn={callbackFn} />)
    modalConfiguration.size = 'md';
    openModal(modalConfiguration)
  }

  const openDeleteDialog = function(consultationId) {
    const modalConfiguration = MODAL_CONFIGURATIONS.find((conf) => conf.type == MODAL_TYPES.DELETE)
    modalConfiguration.confirmFn = async function() {
      return deleteConsultation(consultationId).then((response) => {
        toast.success(response.message, toastOptions)
        if(consultationId == currentConsultationId) {
          setCurrentConsultationId(null)
        }
      }).catch((err) => console.log(err))
      .finally(() => {publish(MODAL_EVENTS.MODAL_CLOSE)})
    }
    modalConfiguration.component = (<DeleteConsultationPrompt />)
    openModal(modalConfiguration)
  }


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  return (
    <section>
      <div>
        <div className="table-head-sec">
          <h2>Visits</h2>
          <div className="d-flex">
            <button className="btn btn-primary d-none" onClick={onCreateNewConsultation}>Add Visit</button>
          </div>
        </div>
        <Box sx={{ width: '100%' }}>
          <Paper sx={{ width: '100%', mb: 2 }}>
            {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size='medium'
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={data.rows.length}
                  isCheckboxAllowed={isCheckboxAllowed}
                />
                <TableBody>
                  {data.rows.map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => {handleClick(event, row.name); setCurrentConsultationId(row.consultation_id)}}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row._id}
                        selected={isItemSelected}
                        sx={{ cursor: 'pointer' }}
                      >

                        {/* Checkbox */}

                        {isCheckboxAllowed && 
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell>
                        }

            
                        <TableCell align="left">{ moment(row.consultationAt).format("MM-DD-YYYY") }</TableCell>
                        <TableCell align="left">{ row.consultation_name }</TableCell>
                        {/* <TableCell align="left">{row.text.substring(0,50)}{row.text.length>50 ? '...' : ''}</TableCell> */}

                        {/* Actions */}

                        <TableCell align="left" className='position-relative'> 
                          <button className="btn btn-danger ms-2 p-1" onClick={(event) => { event.stopPropagation(); openDeleteDialog(row.consultation_id)}}><DeleteIcon /></button>
                        </TableCell>

                      </TableRow>
                    );
                  })}
                  {
                    data.rows.length == 0 && !isLoading &&
                    <TableRow>
                      <TableCell align="center" colSpan={5}>
                        <h4>No data to preview</h4> 
                      </TableCell>
                    </TableRow>
                  }
                  {
                    isLoading && 
                    <TableRow>
                      <TableCell align="center" colSpan={5}>
                        <h4>Loading...</h4> 
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data.totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </div>
    </section>
  );
}

export default ConsultationLists