import React, { useContext, useEffect, useState } from 'react'
import Button from 'react-bootstrap/esm/Button';
import { MAX_RECORDING_TIME } from '../../../config/app.config';
import { getRecordingBlob, handleDeleteRecording, handlePauseRecording, handleResumeRecording, handleStartRecording, replaceRecorderOnStopListener } from '../../../services/recorder.service';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { createConsultation, getConsultationDetails, speechToTextConversion, updateConsultation } from '../../../services/api.service';
import { publish } from '../../../services/event.service';
import { ModalHeader, MODAL_CONFIGURATIONS, MODAL_EVENTS, MODAL_TYPES, openModal } from '../../../services/modal.service';
import Form from 'react-bootstrap/Form';
import { useFormik } from 'formik';
import { consultationSchema } from '../../../helpers/validations';
import DeleteIcon from '@mui/icons-material/Delete';
import { getDoubleDigit } from '../../../helpers/utils';

function AddConsultationModal({setConsultation, setConsultationId, callbackFn}) {
    const formik = useFormik({
        initialValues : {
            consultation_name: ""
        },
        validationSchema: consultationSchema, 
        onSubmit : async function(values) {
            createConsultation(values.consultation_name)
            .then(response => {
                setConsultationId(response.data.consultation_id)
                setConsultation({text: "", consultation_name: values.consultation_name})
            })
            .catch(err => console.log(err))
            .finally(() => callbackFn())
        }
    })
    return (
        <section>
            <ModalHeader><h5 className='mb-0'>Add Visit</h5></ModalHeader>
            <div className="p-3 consultation-modal">
                <Form className="row" onSubmit={formik.handleSubmit}>
                    <div className="col-12">
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Patient Initials</Form.Label>
                            <Form.Control autoFocus type="text" name="consultation_name" maxLength={2} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.consultation_name} required/>
                            { formik.touched.consultation_name && formik.errors?.consultation_name && <small className="text-danger">{formik.errors?.consultation_name}</small> }
                        </Form.Group>
                        <Button variant="primary" type="submit" >Add Visit</Button> 
                    </div>
                </Form>
            </div>
        </section>
    )
}

function ConsultationDetail({CurrentConsultationIdContext, note}) {

    const {currentConsultationId, setCurrentConsultationId} = useContext(CurrentConsultationIdContext);
    const [consultation, setConsultation] = useState({ text: "", consultation_name: ""})
  
    const [recorder, setRecorder] = useState(null);
    const [audioChunks, setAudioChunks] = useState([]);
    const [isRecording, setIsRecording] = useState(false);

    const [isApiLoading, setIsApiLoading] = useState(false);
    const [recordingTime, setRecordingTime] = useState(0);
    const [isDeleteRecording, setIsDeleteRecording] = useState(false);

    useEffect(() => {
        if(recordingTime >= MAX_RECORDING_TIME) {
          return handlePauseRecording({isRecording, recorder})
        }
    
        if(isRecording) {
          const timeout = setTimeout(() => {
            setRecordingTime(recordingTime+1)
          }, 1000)
          return () => {clearTimeout(timeout)}
        }
    }, [isRecording, recordingTime])

    useEffect(() => {
        if(audioChunks.length == 0) {
            setRecordingTime(0)
        }
        if(isDeleteRecording) {
            setIsDeleteRecording(false);
        }else if(audioChunks.length > 0){
            convertSpeechToText();
        }

    }, [audioChunks])


    useEffect(() => {
        // do resetting over here
        if(currentConsultationId) {
            stopRecording()
            setConsultation({ text: "", consultation_name: ""})
            fetchConsultationDetails()
        }else{
            setConsultation({ text: "", consultation_name: ""})
        }
    }, [currentConsultationId])

    const fetchConsultationDetails = function() {
        setIsApiLoading(true)
        if(!currentConsultationId) return

        getConsultationDetails(currentConsultationId)
        .then((response) => {
            setConsultation(response.data.consultation)
        })
        .catch((err) => console.log(err))
        .finally(() => setIsApiLoading(false))
    }

    const onCreateNewConsultation = function() {
        const callbackFn = function() {
            publish(MODAL_EVENTS.MODAL_CLOSE)
        }
        const modalConfiguration = MODAL_CONFIGURATIONS.find((conf) => conf.type == MODAL_TYPES.COMPONENT)
        modalConfiguration.component = (<AddConsultationModal setConsultation={setConsultation} setConsultationId={setCurrentConsultationId} callbackFn={callbackFn} />)
        modalConfiguration.size = 'md';
        openModal(modalConfiguration)
    }

    const saveConsulation = function(isAudioSharable = true) {
        
        if(currentConsultationId) {
            setAudioChunks([])
            setIsApiLoading(true);
            updateConsultation(currentConsultationId, consultation.text, (isAudioSharable && audioChunks.length > 0) ? getRecordingBlob({audioChunks}) : null)
            .then((response) => fetchConsultationDetails())
            .catch(err => console.log(err))
            .finally(() => publish(MODAL_EVENTS.MODAL_CLOSE))
        }
    }

    const convertSpeechToText = function() {
        setIsApiLoading(true)
        speechToTextConversion(currentConsultationId, consultation.text, audioChunks.length > 0 ? getRecordingBlob({audioChunks: audioChunks.filter((chunk, index) => index == audioChunks.length-1)}) : null )
        .then(response => {
            setConsultation({...consultation, text: response.data.transcript})
        })
        .catch(err => console.log(err))
        .finally(() => {
            setIsApiLoading(false)
        })
    }


    const deleteRecording = function() {
        if(isRecording) {
            replaceRecorderOnStopListener(recorder, function() {
                handleStartRecording({setRecorder, setAudioChunks, setIsRecording});
                handleDeleteRecording({setAudioChunks})
            })
            setIsDeleteRecording(true);
            recorder.stop();
        }else{
            handleDeleteRecording({setAudioChunks})
        }
    }

    const stopRecording = function() {
        if(isRecording) {
            replaceRecorderOnStopListener(recorder, function() {
                recorder.stream.getTracks().forEach((track) => track.stop())
                handleDeleteRecording({setAudioChunks})
            })
            setIsDeleteRecording(true);
            setIsRecording(false);
            recorder.stop();
        }else{
            setAudioChunks([])
        }
    }


    return (
        <section>
            <div className='row'>
                <div className='col-md-5'>
                    <div className='card'>
                        <div className='card-body'>
                            <div className='recording-type'>
                                <h4>Patient Initials : {consultation.consultation_name || 'NA'}</h4>
                                <div className='time-cont flex-column'>
                                    <h3>{getDoubleDigit(parseInt(recordingTime/60))} : {getDoubleDigit(recordingTime % 60)}</h3>
                                    <div>{recordingTime == MAX_RECORDING_TIME && <small className="text-danger">Maximum recording <strong>{getDoubleDigit(parseInt(recordingTime/60))}</strong> Minutes</small>}</div>
                                </div>
                                <div className='d-flex w-100 pe-3'>

                                    {(audioChunks.length == 0 && !isRecording) && <Button title='Start recording' disabled={!(audioChunks.length == 0 && !isRecording) || !currentConsultationId || isApiLoading} variant="primary" className="btn-sm me-2 col-6" onClick={() => {handleStartRecording({setRecorder, setAudioChunks, setIsRecording});}}><FiberManualRecordIcon /></Button> }
                                    {(audioChunks.length != 0 && !isRecording) && <Button title='Resume recording' disabled={!(audioChunks.length != 0 && !isRecording) || !currentConsultationId || isApiLoading} variant="success" className="btn-sm me-2 col-6" onClick={() => {handleResumeRecording({isRecording, setAudioChunks, setIsRecording, setRecorder});}}><PlayArrowIcon /></Button> }
                                    {(isRecording) && <Button title='Pause recording' disabled={!(isRecording) || !currentConsultationId || isApiLoading} variant="warning" className="btn-sm me-2 col-6" onClick={() => handlePauseRecording({recorder, isRecording})}><PauseIcon /></Button> }
                                    <Button title='Delete recording' disabled={/*!(!isRecording) ||*/ !currentConsultationId || isApiLoading || (audioChunks.length == 0 && !isRecording)} variant="danger" className="btn-sm me-2 col-6" onClick={() => deleteRecording()}><DeleteIcon /></Button> 
                                    {/* <Button disabled={!currentConsultationId || isApiLoading || isRecording} title='Transcribe' variant='secondary' className="btn-sm col-4" onClick={() => saveConsulation(true)}>Transcribe</Button> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-7'>
                    <div className='card'>
                        <div className='card-body'>
                            <div className='consult-desc'>
                                <h4 className='d-flex justify-content-between'>
                                    <span>Visit transcript</span> 
                                    <button 
                                        className="btn btn-sm btn-warning px-3 py-0"
                                        disabled={!currentConsultationId || isApiLoading || isRecording}
                                        onClick={() => saveConsulation(false)}
                                        >Generate Prompt
                                    </button>
                                </h4>
                                <div className='consult-desc-cont'>
                                    {/* <textarea name="postContent" rows={4} /> */}
                                    <textarea disabled={isApiLoading} className='w-100' onChange={(e) => {setConsultation({...consultation, text: e.target.value})}} value={ isRecording ? note : (isApiLoading ? 'Loading...' : consultation.text) }>
                                        { isRecording ? note : (isApiLoading ? 'Loading...' : consultation.text)}
                                    </textarea>
                                    {/* <p>Old recorded text</p> */}
                                </div>
                                <div className='d-flex justify-content-end'>
                                    <button 
                                        disabled={isApiLoading || isRecording} 
                                        className='btn btn-primary btn-sm new-visit-button' 
                                        onClick={onCreateNewConsultation}
                                        >New visit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ConsultationDetail