import { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import TokenRoute from "./TokenRoute";
import TokenError from "../pages/Main/TokenError";
import MainLayout from "../layouts/MainLayout";
import Dashboard from "../pages/Dashboard/Dashboard";
import PageLoader from "../components/PageLoader";
import Verify from "../pages/Main/Verify";
import ForgotPassword from "../pages/Main/ForgotPassword";

const Home = lazy(() => import("../pages/Main/Home"));
const PrivacyPolicy = lazy(() => import("../pages/Main/PrivacyPolicy"));
const Login = lazy(() => import("../pages/Main/Login"));
const Signup = lazy(() => import("../pages/Main/Signup"));

const DashboardLayout = lazy(() => import("../layouts/DashboardLayout"));
const ChangePassword = lazy(() => import("../pages/Dashboard/ChangePassword"))


function MainRoute() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Suspense fallback={<PageLoader />}><Home/></Suspense>} />
            <Route path="login" element={<Suspense fallback={<PageLoader />}><Login/></Suspense>} />
            <Route path="signup" element={<Suspense fallback={<PageLoader />}><Signup/></Suspense>}/>
            <Route path="privacy-policy" element={<Suspense fallback={<PageLoader />} ><PrivacyPolicy /></Suspense>}/>

            <Route path="forgotPassword" element={<ForgotPassword />} />
            <Route path="tokenError" element={<TokenError />} />
          </Route>
          <Route path="/verify/:doctorId" element={<Verify />} />
          <Route path="/token/:token" element={<TokenRoute />} />
          <Route path="/dashboard" element={<Suspense fallback={<PageLoader />}><DashboardLayout/></Suspense>} >
              <Route index element={<Dashboard />} />
              <Route path="changePassword/:doctor_id" element={<Suspense fallback={<PageLoader />}><ChangePassword/></Suspense>}/>
          </Route>

        </Routes>
      </BrowserRouter>
  )
}

export default MainRoute
