import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { verifyDoctor } from '../../services/api.service';
import { useParams } from 'react-router-dom'

function Verify() {
  const {doctorId} = useParams();
  const [message, setMessage] = useState('Loading...')
  
  useEffect(() => {
    verifyDoctor(doctorId)
    .then((response) => setMessage(response.message))
    .catch(err => setMessage(err.message))
  }, [])

  return (
    <div>
      {message}
    </div>
  )
}

export default Verify