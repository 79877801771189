import { useState, createContext } from "react";
import ConsultationDetail from "./Consultation/ConsultationDetail";
import ConsultationLists from "./Consultation/ConsultationLists";

const CurrentConsultationIdContext = createContext();
const note = `- Time in / time out:
- Visit date:
- Associated mileage:
- Documentation time:
- Travel time:

- Primary Medical diagnosis / Onset or exacerbation / Date:
- OT diagnosis / Onset or exacerbation / Date:
- Patient’s age and gender:
- Recent medical event:
- Past medical history:
- PLOF:
- Patient goals:
- Precautions:

- Patient lives: Alone / with other persons / in congregate situation
- Assistance is available: around the clock / daytime / nighttime / occasional / no assistance available
- Current types of assistance received:
- Evaluation of living situation supports and hazards:
- DME available:

- Temperature: taken - "oral", "axillary", "rectal", "temporal", "otic"
- Blood pressure: Position / Side
- Heart rate:
- Respiratory rate:
- O2 sats:
- Mid-treatment vital changes:
- Edema:
- Primary Pain site:
- Secondary pain site:
- Increased by:
- Relieved by:
- Interferes with:

- Speech:
- Vision:
- Hearing:
- Muscle tone:
- Coordination:
- Sensation:
- Endurance:
- Posture:

- Hand dominance:
- Prior hand dominance:
- Fine motor coordination:
- Gross motor coordination:

-Bilateral Upper Extremity (BUE) Strength/ROM:

- Sitting balance: Static / Dynamic
- Standing balance: Static / Dynamic
- Balance deficits due to:

- Bed mobility levels: rolling, supine <> sit
- Transfer levels: STS, bed <> chair, toilet, shower, tub, car
- Self-care levels: toileting, oral hygiene, grooming, shaving, bathing, dressing, fasteners, socks/shoes, feeding, swallowing.
- IADLs: housekeeping, meal prep, clothing care, use of telephone, manage money, manage meds, safety awareness.

- Cognitive status/perception: short/long term memory, safety awareness, judgment, visual/auditory comprehension, stereognosis, spatial awareness, ability to express needs, attention.
- Orientation: person/place/time
`

export default function Dashboard() {

    const [currentConsultationId, setCurrentConsultationId] = useState(null);

    return (
        <CurrentConsultationIdContext.Provider value={{currentConsultationId, setCurrentConsultationId}}>
           <section className="note-section mb-3 d-none">
                <div className="card">
                    <div className="card-body">
                        <div className="card-data">
                            {note.split('\n').reduce((pv, nx) => <>{pv}{nx}<br/></>, <></>)}
                        </div>
                    </div>
                </div>
           </section>
           <ConsultationDetail CurrentConsultationIdContext={CurrentConsultationIdContext} note={note}/>
           <ConsultationLists CurrentConsultationIdContext={CurrentConsultationIdContext}/>
            <footer>
                <div className="footer footer-nagative">
                <p>Copyright 2023 Haplo | All right reserved.</p>
                </div>
            </footer>
        </CurrentConsultationIdContext.Provider>
    )
}