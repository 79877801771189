import * as Yup from "yup";

export const loginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required."),
  password: Yup.string().required("Password is required."),
});

export const signupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required."),
  first_name : Yup.string().required("First name is required."),
  last_name : Yup.string().required("Last name is required."),
  password: Yup.string().min(8).required("Password is required."),
});

export const patientSchema = Yup.object().shape({
  patient_id: Yup.string().optional(),
  name:  Yup.string().required("Name is required."),
  nric_id:  Yup.string().optional(),
  gender:  Yup.string().required("Gender is required."),
  email: Yup.string().email().required("Email is required."),
})

export const consultationSchema = Yup.object().shape({
  consultation_name: Yup.string().required("File name is required.")
})

export const forgotEmailSchema = Yup.object().shape({
  email: Yup.string().required("Email is required.")
})

export const forgotOTPSchema = Yup.object().shape({
  otp: Yup.string().required("OTP is required.")
})

export const forgotPasswordSchema = Yup.object().shape({
    newPassword: Yup.string().min(8, "Password must be minimum 8 characters long.").required("Password is required."),
    confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm Password is required'),
})