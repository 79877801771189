import axios from "axios";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toastOptions } from "../config/toast.config";

const instance = axios.create();
// Request interceptor
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Response interceptor
instance.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if(error.response.status == 401) {
      localStorage.removeItem('token');
      return window.location.href="/tokenError";
    }
    toast.error(error.response.data.error, toastOptions)
    return Promise.reject(error);
  }
);

export default instance;
