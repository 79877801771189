import { toast } from "react-toastify";
import { toastOptions } from "../config/toast.config";

export const handleStartRecording = function({setRecorder, setAudioChunks, setIsRecording}) {
    navigator.mediaDevices.getUserMedia({ audio: true })
        .then((stream) => {
            const mediaRecorder = new MediaRecorder(stream);
            setRecorder(mediaRecorder);

            mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    setAudioChunks((chunks) => [...chunks, event.data]);
                }
            };

            mediaRecorder.onstop = () => {
                mediaRecorder.stream.getTracks().forEach((track) => track.stop())
                setIsRecording(false);
            };

            mediaRecorder.start();
            setIsRecording(true);
        })
        .catch((error) => {
            toast.error("Error accessing microphone: "+error.message, toastOptions)
        });
}

export const handlePauseRecording = ({recorder, isRecording}) => {
    if (recorder && isRecording) {
      recorder.stop();
    }
};

export const handleResumeRecording = ({isRecording, setRecorder, setAudioChunks, setIsRecording}) => {
    if (!isRecording) {
      return handleStartRecording({setAudioChunks, setIsRecording, setRecorder})
    }
};

export const  handlePlayRecording = ({audioChunks}) => {
    const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
    const audioUrl = URL.createObjectURL(audioBlob);
    const audio = new Audio(audioUrl);
    audio.play();
};

export const getRecordingUrl = ({audioChunks, setAudioUrl}) => {
    const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
    const audioUrl = URL.createObjectURL(audioBlob);
    return setAudioUrl(audioUrl);
}

export const getRecordingBlob = ({audioChunks}) => {
    return new Blob(audioChunks, { type: 'audio/wav' });
}

export const handleDeleteRecording = ({setAudioChunks}) => {
    setAudioChunks([]);
};

export const replaceRecorderOnStopListener = function(recorder, callbackFn) {
    recorder.onstop = callbackFn
}