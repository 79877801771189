import ReactDOM from "react-dom/client"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from "react";
import { subscribe, unsubscribe } from "./event.service";

const unmountRoot = function(root) {
    setTimeout(() => {root?.unmount()}, 150)
}

const ConfirmationDialog = function(props) {
    const [show, setShow] = useState(true);

    const closeModal = () => {
        setShow(false)
        unmountRoot(props.root)
    };

    const confirm = async function() {
        try{
            await props.confirmFn()
        }catch(err) {
            console.error(err)
        }finally{
            closeModal()
        }
    }

    const reject = async function() {
        try{
            await props.rejectFn()
        }catch(err) {
            console.error(err)
        }finally{
            closeModal()
        }
    }

    return (
        <>
          <Modal show={show} onHide={closeModal}>
            {props.component}
            <Modal.Footer>
              <Button variant="secondary" onClick={reject}>
                Close
              </Button>
              <Button 
                variant={props.type == 'delete' ? "danger" : "primary"} 
                onClick={confirm}>
                { props.type == 'delete' ? 'Delete record' : 'Save Changes' }
              </Button>
            </Modal.Footer>
          </Modal>
        </>
    );
  
}

const ComponentDialog = function(props) {
    const [show, setShow] = useState(true);

    const closeModal = () => {
        setShow(false)
        unmountRoot(props.root)
    };

    subscribe(MODAL_EVENTS.MODAL_CLOSE, function modalClose(){
        console.log("MODAL_EVENTS.MODAL_CLOSE")
        closeModal()
        unsubscribe(MODAL_EVENTS.MODAL_CLOSE, modalClose)
    })

    return (
        <>
          <Modal size={props.size} show={show} onHide={closeModal}>
            {props.component}
          </Modal>
        </>
    );
}


export const openModal = function(configuration) {

    if(!document || !configuration) {return;}
    let modal = null

    const root = ReactDOM.createRoot(document.getElementById('modal-container'));
    configuration.root = root;
    switch(configuration.type) {
        case MODAL_TYPES.CONFIRMATION: {
            modal = <ConfirmationDialog {...configuration} />
            break;
        }
        case MODAL_TYPES.DELETE: {
            modal = <ConfirmationDialog {...configuration} />
            break;
        }
        case MODAL_TYPES.COMPONENT: {
            modal = <ComponentDialog {...configuration} />
        }
    }

    if(!modal) {return;}
    root.render(modal)
    return modal;
}


export const MODAL_TYPES = {
    'CONFIRMATION' : 'confirmation',
    'DELETE' : 'delete',
    'COMPONENT' : 'component'
}

export const MODAL_EVENTS = {
    'MODAL_CLOSE': 'modal_close'
}


export const MODAL_CONFIGURATIONS = [
    {
        type: MODAL_TYPES.CONFIRMATION,
        confirmFn: async () => {},
        rejectFn: async () => {},
        component: <></>
    },
    {
        type: MODAL_TYPES.DELETE,
        confirmFn: async () => {},
        rejectFn: async () => {},
        component: <></>
    },
    {
        type: MODAL_TYPES.COMPONENT,
        component: <></>,
        size: 'lg'
        
    }
]


export const ModalContainer = function(){
    return (<div id="modal-container"></div>)
}

export const ModalHeader = function({children}) {
    return (
        <Modal.Header closeButton>
          <Modal.Title className="w-100">{children}</Modal.Title>
        </Modal.Header>
    )
}

export const ModalBody = function({children}) {
    return (
        <Modal.Body>{children}</Modal.Body>
    )
}

export const ModalFooter = function({children}) {
    return (
        <Modal.Footer>{children}</Modal.Footer>
    )
}