import React from "react";
import { Navigate, Outlet } from "react-router-dom";

function MainLayout() {
  if(localStorage.getItem('token')) {
    return <Navigate to={'/dashboard'} />
  }
  return (
    <>
        <Outlet />
    </>
  );
}

export default MainLayout;
