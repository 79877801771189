import React from 'react';
import favIcon from '../assets/images/favicon.svg';
import logo from '../assets/images/logo.svg';

function PageLoader() {
  return (
    <div>
      <div className='loader-sec'>
        <div className='text-center'>
          <div className="logo logo-blue">
            <img className='favicon' src={favIcon} /><img src={logo} />
          </div>
          {/* <div className="spinner-border text-primary"></div> */}
        </div>
      </div>
    </div>
  )
}

export default PageLoader