import http from "./interceptor.service";
const __BASE_URL__ = process.env.REACT_APP_BACKEND_HOST_DOMAIN;
const deviceType = 'web';

export async function uploadFiles(files) {
    const formData = new FormData();
    formData.append('files', files);
    return http.post(`${__BASE_URL__}/api/v1/upload`, files)
}

export async function updateSession() {
    return await http.patch(`${__BASE_URL__}/api/v1/doctor/session/update`, {device_type: deviceType})
}

export async function verifyDoctor(doctor_id) {
    return await http.post(`${__BASE_URL__}/api/v1/doctor/verify`, {doctor_id})
}

export async function getStats() {
    return await http.get(`${__BASE_URL__}/api/v1/doctor/stats`)
}

export async function loginAccount({email, password}) {
    return await http.post(`${__BASE_URL__}/api/v1/doctor/login`, {email, password})
}

export async function createAccount({first_name, last_name, email, password}) {
    return await http.post(`${__BASE_URL__}/api/v1/doctor/create`, {first_name, last_name, email, password})
}

export async function getDoctorDetails() {
    return await http.get(`${__BASE_URL__}/api/v1/doctor/details`)
}

export async function getPatientsList(page, page_size, order_by, order_type, q) {
    return await http.get(`${__BASE_URL__}/api/v1/patient/list?page=${page}&page_size=${page_size}&order_by=${order_by}&order_type=${order_type}&q=${q}`,)
}

export async function createPatient(data) {
    return await http.post(`${__BASE_URL__}/api/v1/patient/create`, data)
}

export async function getPatientDetails(patient_id) {
    return await http.get(`${__BASE_URL__}/api/v1/patient/details?patient_id=${patient_id}`)
}

export async function updatePatientDetails(data) {
    return await http.put(`${__BASE_URL__}/api/v1/patient/update`, data)
}

export async function deletePatient(patient_id) {
    return await http.delete(`${__BASE_URL__}/api/v1/patient/delete`, {data: {patient_id}})
}

export async function createConsultation(consultation_name) {
    return await http.post(`${__BASE_URL__}/api/v1/consultation/create`, {consultation_name})
}

export async function getConsultationsList(page, page_size, order_by, order_type, q, consultationAt) {
    return await http.get(`${__BASE_URL__}/api/v1/consultation/list?page=${page}&page_size=${page_size}&order_by=${order_by}&order_type=${order_type}&q=${q}`,)
}

export async function getConsultationDetails(consultation_id) {
    return await http.get(`${__BASE_URL__}/api/v1/consultation/details?consultation_id=${consultation_id}`,)
}

export async function updateConsultation(consultation_id, text, blob) {
    const formData = new FormData();
    if(blob) {formData.append('audio', blob)}
    formData.append('consultation_id', consultation_id);
    formData.append('text', text);
    return await http.put(`${__BASE_URL__}/api/v1/consultation/update`, formData)
}

export async function deleteConsultation(consultation_id) {
    return await http.delete(`${__BASE_URL__}/api/v1/consultation/delete`, {data: {consultation_id}})
}

export async function speechToTextConversion(consultation_id, text, blob) {
    const formData = new FormData();
    if(blob) {formData.append('audio', blob)}
    formData.append('text', text);
    formData.append('consultation_id', consultation_id);
    return await http.post(`${__BASE_URL__}/api/v1/consultation/transcript/generate`, formData)
}


export async function forgotPassword(email) {
    return await http.post(`${__BASE_URL__}/api/v1/doctor/forgot`, {email})
}

export async function matchForgotPwdOtp(_id, otp) {
    return await http.post(`${__BASE_URL__}/api/v1/doctor/verify/otp`, {_id, otp})
}

export async function changeForgotPassword(_id, password) {
    return await http.post(`${__BASE_URL__}/api/v1/doctor/change/forgot/password`, {_id, password})
}

export async function changePassword(doctor_id, password) {
    return await http.patch(`${__BASE_URL__}/api/v1/doctor/change/password`, {doctor_id, password})
}