import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import MainRoute from "./routes/MainRoute";
import { ToastContainer } from "react-toastify";
import "./main.scss";
import {ModalContainer} from "./services/modal.service"

function App() {
  return (
    <>
        <ModalContainer />
        <ToastContainer />
        <MainRoute/>
    </>
  );
}

export default App;
