import React from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import { toastOptions } from '../config/toast.config';
import { updateSession } from '../services/api.service';
import jwtDecode from "jwt-decode"

function TokenRoute() {
    const {token} = useParams();
    const navigate = useNavigate();
    try{

        if(localStorage.getItem('token')) {
            return <Navigate to={"/dashboard"} />
        }

        if(token) {
            localStorage.setItem("token", token);
            updateSession()
            .then((res) => {
                const decodeToken = jwtDecode(token);
                if(decodeToken.data.is_third_party_signup) {
                    toast.success("Login successfully.", toastOptions)
                }
                return navigate('/dashboard')
            })
        }else{
            throw "Unauthorized access."
        }
    }catch(err) {
        localStorage.removeItem('token')
        toast.error(err.message, toastOptions)
        navigate('/')
    }
}

export default TokenRoute
