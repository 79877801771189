import React from 'react'
import { useFormik } from "formik"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { changeForgotPassword, forgotPassword, loginAccount, matchForgotPwdOtp } from '../../services/api.service';
import { forgotEmailSchema, forgotOTPSchema, forgotPasswordSchema } from '../../helpers/validations';
import { toast } from 'react-toastify';
import { toastOptions } from '../../config/toast.config';
import { Link, useNavigate } from 'react-router-dom';
import sliderBg from '../../assets/images/slider-img.png';
import favIcon from '../../assets/images/favicon.svg';
import logo from '../../assets/images/logo.svg';
import { useState } from 'react';

function ForgotPassword() {
    const [emailExists, setEmailExists] = useState(false);
    const [otpMatched, setOtpMatched] = useState(false);
    const [otpId, setOtpId] = useState("");

    const emailFormik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: forgotEmailSchema,
        onSubmit: async function(values, {setSubmitting}) {
            const res = await forgotPassword(values.email)
            setOtpId(res.data._id);
            toast.success(res.message, toastOptions);
            setEmailExists(true);
            setSubmitting(false)
        }
    })

    const otpFormik = useFormik({
        initialValues: {
            otp: "",
        },
        validationSchema: forgotOTPSchema,
        onSubmit: async function(values, {setSubmitting}) {
            const res = await matchForgotPwdOtp(otpId, values.otp)
            toast.success(res.message, toastOptions);
            setSubmitting(false)
            setOtpMatched(true);
        }
    })

    const passwordFormik = useFormik({
        initialValues: {
            newPassword: "",
            confirmPassword: ""
        },
        validationSchema: forgotPasswordSchema,
        onSubmit: async function(values, {setSubmitting}) {
            const res = await changeForgotPassword(otpId, values.newPassword);
            toast.success(res.message, toastOptions);
            setEmailExists(false);
            setOtpMatched(false);
            setOtpId("");
            setSubmitting(false)
        }
    })

  return (
    <section className='login-page'>
        <div className="container-fluid px-0">
            <div className="row align-items-center">
                <div className="col-lg-8 col-md-8 col-12 d-none d-lg-block">
                    <img className='left-img' src={sliderBg} />
                </div>
                <div className="col-lg-4 col-md-4 col-12">
                    <div className="login-card">
                            <div className="">
                                <Link to={'/'} className='logo logo-blue'><img width={'100'} className='favicon' src={favIcon} /><img src={logo} /></Link>
                            </div>
                            <h2 className='mb-3'>Forgot password</h2>
                            {
                                !emailExists && !otpMatched ? 
                                    <Form onSubmit={emailFormik.handleSubmit}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control type="email" name="email" placeholder="Enter email" onChange={emailFormik.handleChange} onBlur={emailFormik.handleBlur} value={emailFormik.values.email}/>
                                            { emailFormik.touched.email && emailFormik.errors?.email && <small className="text-danger">{emailFormik.errors?.email}</small>}
                                        </Form.Group>
                                        <div className="d-flex w-100 align-items-center mb-3">
                                            <Button variant="primary" type="submit" disabled={emailFormik.isSubmitting}>
                                                Submit
                                            </Button>
                                        </div>
                                    </Form>
                                :
                                    null
                            }

                            {
                                emailExists && !otpMatched?
                                 <Form onSubmit={otpFormik.handleSubmit}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>OTP</Form.Label>
                                        <Form.Control type="password" name="otp" placeholder="Enter OTP" onChange={otpFormik.handleChange} onBlur={otpFormik.handleBlur} value={otpFormik.values.otp}/>
                                        { otpFormik.touched.otp && otpFormik.errors?.otp && <small className="text-danger">{otpFormik.errors?.otp}</small>}
                                    </Form.Group>
                                    <div className="d-flex w-100 align-items-center mb-3">
                                        <Button variant="primary" type="submit">
                                            Submit
                                        </Button>
                                    </div>
                                </Form>
                                :
                                null
                            }

                            {
                                otpMatched && <Form onSubmit={passwordFormik.handleSubmit}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>New password</Form.Label>
                                        <Form.Control type="password" name="newPassword" placeholder="Enter new password" onChange={passwordFormik.handleChange} onBlur={passwordFormik.handleBlur} value={passwordFormik.values.newPassword}/>
                                        { passwordFormik.touched.newPassword && passwordFormik.errors?.newPassword && <small className="text-danger">{passwordFormik.errors?.newPassword}</small>}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Confirm new password</Form.Label>
                                        <Form.Control type="password" name="confirmPassword" placeholder="Confirm new password" onChange={passwordFormik.handleChange} onBlur={passwordFormik.handleBlur} value={passwordFormik.values.confirmPassword}/>
                                        { passwordFormik.touched.confirmPassword && passwordFormik.errors?.confirmPassword && <small className="text-danger">{passwordFormik.errors?.confirmPassword}</small>}
                                    </Form.Group>
                                    <div className="d-flex w-100 align-items-center mb-3">
                                        <Button variant="primary" type="submit">
                                            Submit
                                        </Button>
                                    </div>
                                </Form>
                            }

                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default ForgotPassword